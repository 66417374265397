import { NgClass } from '@angular/common';
import {
  Component,
  computed,
  effect,
  input,
  model,
  output,
  signal,
  WritableSignal
} from '@angular/core';
import { NgxTolgeeModule } from '@tolgee/ngx';

import moment from 'moment';

type CalendarMonth = {
  month: moment.Moment,
  days: moment.Moment[]
};

type Calendar = CalendarMonth[];

export type Custom<T> = {
  from?: T,
  to?: T,
  css?: string[],
  data?: { [key: string]: unknown }, // Data to give back if selection is inside the range
  //callback, etc...
}

@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [
    NgxTolgeeModule,
    NgClass
  ],
  templateUrl: './date-picker.component.html'
})
export class DatePickerComponent {

  notBefore = input(null, {
    transform: (value: Date | null) => value ?
      moment.utc([ value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate() ]) :
      null
  })

  notAfter = input(null, {
    transform: (value: Date | null) => value ?
      moment.utc([ value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate() ]) :
      null
  })

  customs = input([], {
    transform: (values: Custom<Date>[]) => values.map(value => ({
      ...value,
      from: value.from ?
        moment.utc([ value.from.getUTCFullYear(), value.from.getUTCMonth(), value.from.getUTCDate() ]) :
        undefined,
      to: value.to ?
        moment.utc([ value.to.getUTCFullYear(), value.to.getUTCMonth(), value.to.getUTCDate() ]) :
        undefined,
    }))
  })

  defaultClasses = input<string>('');

  depth = input(1);
  selected = model<Date | undefined>(undefined)
  selectedAsMoment = computed(() => {
    const selected = this.selected();
    if (selected) {
      return moment(this.selected());
    }
    return null;
  });
  viewed: WritableSignal<{ from: moment.Moment, to: moment.Moment }>;
  selectedDataChange = output<unknown>();
  selectedViewedChange = output<{ from: Date, to: Date }>();
  calendar: Calendar | null = null;
  today = moment.utc();

  constructor() {
    const from = this.today.clone().startOf('month');
    const to = this.today.clone().add(this.depth() - 1, 'month').endOf('month');
    this.viewed = signal({ from, to });
    let lastEmitted = this.viewed();
    effect(() => {
      const depth = this.depth();
      this.viewed.update(({ from: oldFrom }) => {
        const from = oldFrom.clone().startOf('month');
        const to = from.clone().add(depth - 1, 'month').endOf('month');
        return { from, to };
      })
    }, { allowSignalWrites: true });
    effect(() => {
      const viewed = this.viewed();
      this.customs();
      const calendar: Calendar = [];
      const depth = viewed.to.diff(viewed.from, 'month');
      let currentDepth = 0
      do {
        const days: moment.Moment[] = [];
        let cursor = moment.utc(viewed.from.clone().add(currentDepth, 'month').startOf('week'));
        const end = viewed.from.clone().add(currentDepth, 'month').endOf('month').endOf('week');
        while (cursor <= end) {
          days.push(cursor);
          cursor = moment.utc(cursor).add(1, 'day');
        }
        calendar.push({ month: viewed.from.clone().add(currentDepth, 'month'), days });
      } while (currentDepth++ < depth)
      this.calendar = calendar;
      if (viewed !== lastEmitted) {
        lastEmitted = viewed
        this.selectedViewedChange.emit({ from: viewed.from.toDate(), to: viewed.to.toDate() });
      }
    })
  }

  select(day: moment.Moment): void {
    let data = {};
    for (const custom of this.customs()) {
      if (!custom.data) {
        continue;
      }
      if (custom.from && day.isBefore(custom.from, 'day')) {
        continue;
      }
      if (custom.to && day.isAfter(custom.to, 'day')) {
        continue;
      }
      data = { ...data, ...custom.data };
    }
    const { from, to } = this.viewed();
    if (day.isBefore(from, 'month')) {
      this.changeReference(-1);
    } else if (day.isAfter(to, 'month')) {
      this.changeReference(1);
    }
    this.selected.set(day.toDate());
    this.selectedDataChange.emit(Object.keys(data).length ? data : null);
  }

  classes(day: moment.Moment, month: CalendarMonth, idx: number): string[] {
    const cls = new Set<string>();
    let hasCustom = false;
    for (const custom of this.customs()) {
      if (!custom.css?.length) {
        continue;
      }
      if (custom.from && day.isBefore(custom.from, 'day')) {
        continue;
      }
      if (custom.to && day.isAfter(custom.to, 'day')) {
        continue;
      }
      for (const css of custom.css) {
        cls.add(css)
        hasCustom = true;
      }
    }
    if (!hasCustom) {
      cls.add(this.defaultClasses())
    }
    cls.add(day.isSame(month.month, 'month') ? 'bg-white' : 'bg-gray-50');
    if (this.selectedAsMoment()?.isSame(day, 'day') && day.isSame(month.month, 'month')) {
      cls.add('text-white');
      cls.add('font-semibold');
      cls.add('!bg-vertCaliceo-100');
    } else if (this.today.isSame(day, 'day')) {
      cls.add('text-bleuCaliceo-300')
      cls.add('font-semibold');
    } else {
      cls.add(day.isSame(month.month, 'month') ? 'text-gray-900' : 'text-gray-400')
    }
    if (idx === 0) {
      cls.add('rounded-tl-lg')
    } else if (idx === 6) {
      cls.add('rounded-tr-lg')
    } else if (idx === (month.days.length ?? 0) - 7) {
      cls.add('rounded-bl-lg')
    } else if (idx === (month.days.length ?? 0) - 1) {
      cls.add('rounded-br-lg')
    }
    return [ ...cls ];
  }

  changeReference(number: number): void {
    this.viewed.update(({ from: oldFrom }) => {
      const from = oldFrom.clone().add(number, 'month').startOf('month');
      const to = from.clone().add(this.depth() - 1, 'month').endOf('month');
      return { from, to };
    });
  }
}
